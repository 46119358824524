*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.main {
  min-height:90vh;
}

.header-img {
  transition:all 250ms ease-in-out;
  -webkit-transition:all 250ms ease-in-out;
  -moz-transition:all 250ms ease-in-out;
  -ms-transition:all 250ms ease-in-out;
  -o-transition:all 250ms ease-in-out;
}
.header-img:hover {
  transform:scale(0.90);
  -webkit-transform:scale(0.90);
  -moz-transform:scale(0.90);
  -ms-transform:scale(0.90);
  -o-transform:scale(0.90);
  filter:grayscale(50%);
  -webkit-filter:grayscale(50%);
}

.primary-btn {
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  border:1px solid #F05537;
  border-radius:25px;
  -webkit-border-radius:25px;
  -moz-border-radius:25px;
  -ms-border-radius:25px;
  -o-border-radius:25px;
  color:#F05537;
  transition:all 250ms ease-in-out;
  -webkit-transition:all 250ms ease-in-out;
  -moz-transition:all 250ms ease-in-out;
  -ms-transition:all 250ms ease-in-out;
  -o-transition:all 250ms ease-in-out;
}
.primary-btn:hover {
  border:1px solid #F05537;
  background-color: #F05537;
  color:#fff;
}

.primary-color {
  color:#F05537 !important;
}
.primary {
  transition: all 250ms ease-in-out;
}
.primary:hover {
  color:#F05537 !important;
}
.primaryActive {
  color:#F05537 !important;
}
.bg-color {
  background-color:#F05537;
}
.pagiBtn {
  background-color:#F05537 !important;
  color: #fff !important;
}
.link-color {
  color: #222;
}
.anime {
  animation: fadeIn 0.3s;
}
@keyframes fadeIn {
  0% { opacity: 0; transform: scale(0.98); }
  100% { opacity: 1;transform: scale(1); }
}

.event-card {
  box-shadow: 0 16px 64px -16px rgba(46,55,77,.1);
  border:1px solid transparent;
  transition:all 250ms ease-in-out;
  -webkit-transition:all 250ms ease-in-out;
  -moz-transition:all 250ms ease-in-out;
  -ms-transition:all 250ms ease-in-out;
  -o-transition:all 250ms ease-in-out;
  /* height:100%; */
  width: 300px;
}
.event-card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border:1px solid #F05537;
}
.card-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.card-desc * {
  font-size: 16px !important;
  font-weight: normal !important;
}
.card-desc hr {
  display: none !important;
}
.event-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.text-size {
  font-size: .875rem;
}